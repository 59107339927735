import { graphql } from "gatsby";
import React from "react";
import About from "../../components/About";
import Layout from "../../components/Layout";

function AboutPage({ location, data }) {
  return (
    <Layout location={location} languages={data.site.siteMetadata.languages}>
      <About data={{ ...data.data, photo: data.photo.photo }} />
    </Layout>
  );
}

export default AboutPage;

export const pageQuery = graphql`
  query AboutEsQuery {
    site {
      siteMetadata {
        languages {
          defaultLangKey
          langs
        }
      }
    }
    photo: datoCmsAbout(locale: { eq: "en" }) {
      photo {
        gatsbyImageData(layout: CONSTRAINED, width: 800)
      }
    }
    data: datoCmsAbout(locale: { eq: "es" }) {
      title
      description {
        value
      }
    }
  }
`;
